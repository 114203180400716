import { template as template_dd4cc9574f3c4c268fb8d3eff2bc1964 } from "@ember/template-compiler";
const WelcomeHeader = template_dd4cc9574f3c4c268fb8d3eff2bc1964(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
