import { template as template_00d90de561a9498089bffa838869ea2f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_00d90de561a9498089bffa838869ea2f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
