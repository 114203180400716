import { template as template_485e55760df54f9f95feba71b9b1468b } from "@ember/template-compiler";
const FKText = template_485e55760df54f9f95feba71b9b1468b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
