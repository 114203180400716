import { template as template_b4fd1f7e6b914371b9f28cd1603731aa } from "@ember/template-compiler";
const FKLabel = template_b4fd1f7e6b914371b9f28cd1603731aa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
